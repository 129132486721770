import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Representante from './representante';
import Pasos from './pasos';
import { Ver_Valores, conexiones, nuevo_Valores } from '../../../constantes';
import Cargando from '../../../componentes/esperar/cargar';
import { useEffect } from 'react';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Condicion_Estudiante } from '../funciones';
import { Icon } from '@mui/material';

export default function Pagar(props) {
  
  const [state, setState]= useState({
    pantalla:props.Representante ? 'Pasos' : 'Representante', 
    datos: props.Representante ? props.Representante : [],
    monstrar_representante: !props.Representante,
    inscripcion: props.Inscripcion,
    Refrescar: props.Refrescar,
    formapago: props.formapago,
    Formas_pago:props.Formas_pago,
    Config:props.Config,
    Subtotalvalor: props.Subtotalvalor ? props.Subtotalvalor : {}
  });
  
  const Cambio = async(valor) =>{
    // console.log('Por cambios ...',valor)
    if (valor.paso==='representante' ){
      //.........................Despues de salir de Representante...............
      setState({...state,pantalla:'cargando'});
      const anterior = {...valor};
      let {datos} = valor;
      datos.valores.representados[0].estado=<Icon>open</Icon>;
      let respu= await conexiones.Leer_C(['uecla_Pago','uecla_Whatsapp_Capture'],{
        uecla_Pago:{ $and: [ { "valores.Representante":datos._id}]},
        uecla_Whatsapp_Capture:{$and: [{"valores.estatus":"0"}]}
      })
      let pendienteWhatsapp=[];
      if (respu.Respuesta==='Ok'){
        let pend = [];
        let rech = [];
        respu.datos.uecla_Pago.map(val=>{
            if (val.valores.estatus==='0' && val.eliminado!==true){
                pend=[...pend, val];
            }else if(val.valores.estatus==='1' && val.eliminado!==true){
                rech=[...rech, val];
            }
            return val
        })
        pendienteWhatsapp = respu.datos.uecla_Whatsapp_Capture.filter(f=> (f.eliminado===false || f.eliminado===undefined)&& f.valores.representante._id===datos._id);
        valor={...valor,paso:'',pendienteWhatsapp, Verificar:{pend, rech, pendienteWhatsapp}, Recibo:undefined}
        
        if (pend.length!==0){
          const dato = pend[0];
          valor={...valor,
            Mensualidades:dato.valores.Mensualidades, 
            Formas:undefined, 
            Formas_pago:dato.valores.Formas_pago, 
            formas_pago:dato.valores.Formas_pago,
            formapago:dato.valores.Formas_pago,
            Subtotalvalor:dato.valores.Subtotalvalor,
            Totales: dato.valores.Totales,
            files:dato.valores.files,
            valorCambio:dato.valores.valorCambio,
            Pendiente:true,
            id_pago: dato._id
          }
        }else{
          valor={...valor,
            Mensualidades:{meses:[]}, 
            Formas:undefined, 
            Formas_pago:undefined, 
            formas_pago:undefined, 
            formapago:undefined,
            Subtotalvalor:{abono:datos.valores.abono, abonod:datos.valores.abonod},
            Totales: undefined,
            files:undefined, 
            Pendiente:false, 
            id_pago: undefined
          }
        }
      }
      //Para las mensualidades ..................
      
      if (datos.valores && datos.valores.representados){
        let periodo = await conexiones.Leer_C(['uecla_Inscripcion'], {uecla_Inscripcion:{}});
        if (periodo.Respuesta==='Ok'){
          periodo= periodo.datos.uecla_Inscripcion.sort((a,b)=>a.valores.periodo>b.valores.periodo ? 1 : -1);
          periodo=periodo[periodo.length-1].valores.periodo;
        }else{
          periodo=null
        }
        let mens= await conexiones.Mensualidades({Representados:datos.valores.representados});
        if(mens.Respuesta==='Ok' && periodo!==null){
          const solventes= mens.solventes;
          
          datos.valores.representados.map(val=>{
            
            if (val.grado && val.grado._id ===4){// && solventes[val._id].solvente){
              
              const pos = solventes[val._id].periodos.findIndex(f=> f.periodo===periodo );
              
              if (solventes[val._id].periodos.length!==0 && pos===-1 && solventes[val._id].periodos[solventes[val._id].periodos.length-1].periodo<periodo){
                
                confirmAlert({
                  title: solventes[val._id].solvente ? 'Solvente' : 'Pendiente',
                  message:`${val.nombres} ${val.apellidos}, ${solventes[val._id].solvente ? 'esta solvente' : 'con pago(s) pendiente'}, desea cambiar su condición a graduado?`,
                  buttons: [
                    {label: 'SI', onClick: async()=>{
                      setState({...state, pantalla:''})
                      let estudiante = await conexiones.Leer_C(['uecla_Estudiante'],{uecla_estudiante:{_id:val._id}});
                      if (estudiante.Respuesta==='Ok'){
                        estudiante=estudiante.datos.uecla_estudiante[0];
                        estudiante.valores.grado=solventes[val._id].solvente
                          ? 'Graduado'
                          : {
                              "_id": 5,
                              "titulo": "Pendiente",
                              "value": "pendiente",
                              "permisos": ""
                          };
                        estudiante.valores.estatus=solventes[val._id].solvente 
                          ? {
                              "_id": 2,
                              "titulo": "Graduado",
                              "value": "graduado",
                              "permisos": ""
                          }
                          : {
                              "_id": 4,
                              "titulo": "Pagos Pendiente",
                              "value": "pendiente",
                              "permisos": ""
                          }
                        await Condicion_Estudiante(estudiante);
                        let represen = await conexiones.Leer_C(['uecla_Representante'],{uecla_representante:{_id:datos._id}});
                        represen = represen.datos.uecla_representante[0];
                        
                        Cambio({...anterior, datos:represen})  
                        
                        
                      }
                    }},
                    {label:'NO'}
                  ]
                });
              }
              

            }else if (Number(val.beca)===100){
              
              const pos = solventes[val._id].periodos.findIndex(f=> f.periodo===periodo );
              console.log(val, solventes[val._id], pos)
              if (pos===-1){
                confirmAlert({
                  title: 'Exonerado',
                  message:`${val.nombres} ${val.apellidos}, becado a 100%, desea inscribir en periodo ${periodo}`,
                  buttons: [
                    {label: 'SI', onClick: async()=>{
                      // setState({...state, pantalla:''})
                      let valores = {
                          _id_estudiante:val._id, periodo,
                          cedula:val.cedula, nombres:val.nombres, apellidos:val.apellidos,
                          grado:val.grado && val.grado.titulo ? val.grado.titulo : val.grado,
                          seccion: val.seccion && val.seccion.titulo ? val.seccion.titulo : val.seccion
                      }
                      const meses = Ver_Valores().config.Listas.lista_Meses;
                      meses.map(val=>{
                        valores[val.value]=true;
                        valores[`mensaje-${val.value}`]= 'EXONERADO'
                        return val
                      });
                      let nuevos= await conexiones.Guardar({valores, multiples_valores:true},'uecla_Mensualidad');
                      if (nuevos.Respuesta==='Ok'){

                      }
                    }},
                    {label:'NO'}
                  ]
                });
              }
            }
            return val;
          })

        }

      }
    }
    valor={...state, ...valor}
    if (valor.formapago){
      valor = Calcular(valor)
    }
    setState({...valor});
    nuevo_Valores({datosActuales:valor});
  }
  const Aprobar = (val)=>{
    let aprobar = true;
    if ([undefined, null, ''].indexOf(val)!==-1){
      aprobar=false;
    }
    return aprobar
  }
  const Calcular = (valores) =>{
    let {formapago}= valores;
    const {valorCambio}= state;
    let bolivar=0;
    let dolar=0;
    let abono=0;
    let abonod=0;
    let aprobar = true;
    formapago.map(val=>{
      // console.log(val)
      // Object.keys(val).filter(f=>['_id','titulo','value','permisos','id'].indexOf(f)===-1)
      const operaciones = ["bancoo","bancod","referencia"]
      operaciones.map(n=>{
        // console.log(val.value, n);
        if (
          (n==='bancoo' && ['transferencia','debito', 'pagomovil'].indexOf(val.value)!==-1)
          ||
          (n==='bancod' && ['transferencia', 'pagomovil'].indexOf(val.value)!==-1)
          ||
          (n==='referencia')
        ){
          aprobar = Aprobar(val[n]);
        }
        // if(val.value==='debito' && ['bancod'].indexOf(n)===-1){
        //   aprobar = Aprobar(val[n]);
        // }else if (['efectivobolivar','efectivodolar'].indexOf(val.value)!==-1 && ['monto'].indexOf(n)!==-1){
        //   aprobar = Aprobar(val[n]);
        // }
        return n
      })
      if (['efectivodolar','zelle','otro'].indexOf(val.value)===-1 ||(val.value==='otro' && val.moneda==='Bs')){
        bolivar+= val.monto ? Number(val.monto) : 0;
      }else{
        dolar+= val.monto ? Number(val.monto) : 0;
      }
      
      return val
    })
    const total= bolivar + (dolar * valorCambio);
    const totald= dolar + (bolivar / valorCambio);
    abono = bolivar!==0 ? total - state.Subtotalvalor.total : 0;
    abonod = dolar!==0 && bolivar===0 ? totald - state.Subtotalvalor.totald : 0;
    let restan = total - state.Subtotalvalor.total;
    let restand = totald - state.Subtotalvalor.totald;
    
    let mensaje = '';
    if (Number(restan.toFixed(2))<0){
      mensaje= `El monto es menor al monto a cancelar${!aprobar? ', ': ''}`;
    }
    if (!aprobar){
      mensaje = mensaje + 'Debe indicar todos los datos';
    }
    if (mensaje==='') mensaje = 'Puede continuar'
    return {...valores, Totales:{bolivar, total, abono, restan, dolar, totald, abonod, restand, mensaje}}
  }

  const Enviar = async() =>{
    const fecha = moment().format('DD/MM/YYYY');
    const {User, tipo}= Ver_Valores();
    if (tipo==='Electron'){
      await conexiones.Leer_C(['uecla_Recibo'], 
        {
          uecla_Recibo:{pagina: 1, condicion:{$or:[{eliminado:false},{eliminado:undefined}]}, cantidad:1, pag:0, sort:{'valores.recibo':-1}},
        }
      );
      // console.log(result)
    }
    const {datos, formapago, Mensualidades, Subtotalvalor, Totales, valorCambio, files, id_pago, Pendiente, Motivo_rechazo} = state;
    const Formas_pago = formapago.map(valor=>{
      return {...valor, fecha : [undefined, null, false].indexOf(valor.fecha)!==-1 ? fecha: valor.fecha}
    });
    setState({...state, paso:'enviando', enviando:true})
    const pago = [4,'4'].indexOf(User.categoria)!==-1;
    const resultado = await conexiones.Enviar_pago({Representante:datos._id, Formas_pago, Mensualidades, Subtotalvalor, Totales, valorCambio, files, pago, id_pago, Pendiente, Motivo_rechazo})
    // console.log(resultado);
    if (resultado.Respuesta==='Ok'){
      nuevo_Valores({datosActuales:{...Ver_Valores().datosActuales,paso:'',Recibo:resultado.dato, pagoEnviado:resultado.pagoEnviado}})
      setState({...state, paso:'', enviando:false, Recibo: resultado.dato, pagoEnviado:resultado.pagoEnviado})
      if (pago){
        Cambio({...state, paso:'representante'})
      }
    }else{
      setState({...state, enviando:false})
    }
    if (state.Refrescar){
      state.Refrescar();
    }
    return resultado
  }
  const Inicio =async()=>{
    const {User}= Ver_Valores();
    let respu= await conexiones.Leer_C(['uecla_Representante'],{
      uecla_Representante:{_id:User._id}
    })
    let representate=respu.datos.uecla_Representante[0];
    if (state.inscripcion && representate===undefined){
      representate=props.Representante
    }
    if ([4,'4'].indexOf(User.categoria)!==-1 || state.inscripcion!==undefined){
      
      Cambio({
          datos:representate, pantalla:'Pasos', 
          paso:'representante',
          monstrar_representante:false, 
          Subtotalvalor: {abono:representate.valores.abono, abonod:representate.valores.abonod},
          Mensualidades:{meses:[]},
          Meses:{},
          Formas:undefined, Formas_pago:undefined, formas_pago:undefined, 
      })
    }
    
  }
  useEffect(()=>{
    Inicio();
    return ()=>{

    }
  },[])
  return state.pantalla==='Representante'
    ? <Representante  Cambio={Cambio} Config={props.Config}/>
    : state.pantalla==='Pasos'
    ? <Pasos {...state} Cambio={Cambio} Enviar={Enviar}/>
    : (
        <Box sx={{ flexGrow: 1 }}>
          <Cargando open={true} Config={state.Config}/>
        </Box>
      );
}