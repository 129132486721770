import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tabla from '../../../componentes/herramientas/tabla';
import Formulario from '../../../componentes/herramientas/formulario';
import { genera_formulario, conexiones, Form_todos, Ver_Valores, Titulos_todos } from '../../../constantes';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import Cargando from '../../../componentes/esperar/cargar';
import Logo from '../../../imagenes/logo.png';
import { Typography } from '@mui/material';
import Dialogo from '../../../componentes/herramientas/dialogo';
import Evaluaciones from './evaluaciones';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'rgb(5, 30, 52)',
  backgroundImage: "linear-gradient(180deg, #00ffff 0, #00e5ff 12.5%, #10a6f8 25%, #10a6fa 37.5%, #1e6ca3 50%, #1e6ca5 62.5%, #153959 75%, #15395b 87.5%, #000000 100%)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  
}));

export default function Notas(props) {
    const {Config} = props;
    const [state, setState] = React.useState({cargando:true});
    const [dialogo, setDialogo]= React.useState({
        open:false,  
    });
    const [cargando, setCargando] = React.useState(true);
    const cambio = (valores)=>{
        setState({...state,...valores})
    }

    const Seleccion = async(valores)=>{
        const {resultados}= valores;
        if(resultados.tipo && resultados.periodo && resultados.lista && resultados.grado){
            setCargando(valores)
        }
        
        return valores
    }
    
    const Agregar = ()=>{
        const {datos, formulario} = state;
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:'md',
            Titulo:`Evaluaciones de ${datos.grado} ${datos.seccion}, periodo ${datos.periodo}`,
            Cuerpo: <Evaluaciones Titulo= {datos.asignatura.titulo} Datos={datos}/>,
            Cerrar: ()=>{
                setDialogo({...dialogo,open:false});
                Seleccion({name:'tipo', resultados:formulario.datos})
            },
        })
    }

    const Guardar = async(valores)=>{
        const {datos, formulario, est_titulos}= state;
        let nota ={
            asignatura: datos.asignatura,
            // docente: datos.docente
        };
        
        // let cantidad=0;
        Object.keys(valores).map(val=>{
            if (val.indexOf('Error-')===-1 
                    && val.indexOf('titulo')===-1 
                    && val.indexOf('total-')===-1
                    && val.indexOf('lapso')===-1
            ){
                nota[val]=valores[val];
                if (val.indexOf('nota-')===0 && val.indexOf('nota-Error-')===-1){
                    const pos = est_titulos.findIndex(vl=> vl.field===val);
                    if (pos!==-1){
                        const etiqueta =`${est_titulos[pos].lapso.value}-${datos.asignatura._id}`;
                        if (nota[etiqueta] && nota[etiqueta]!==''){
                            nota[etiqueta].cantidad+=1;
                            nota[etiqueta].nota+=Number(nota[val]);
                        }else{
                            nota[etiqueta]={cantidad:1,nota:Number(nota[val])}
                        }
                    }
                }
            }
            return val;
        })
        Object.keys(nota).map(val=>{
            if (val.indexOf('lapso-')!==-1){
                nota[val]=nota[val].nota/nota[val].cantidad
            }
            return val
        })
        console.log(valores._id, nota)
        let nuevos= await conexiones.Guardar({_id:valores._id, valores:nota, multiples_valores:true},'uecla_Nota');
        if (nuevos.Respuesta==='Ok'){
            setDialogo({...dialogo,open:false});
            Seleccion({name:'tipo', resultados:formulario.datos})
        }
        return nuevos
    }   
    
    const Abrir = async(valor) =>{
        const {datos, est_titulos} = state;
        
        if (datos.tipo==='seccion'){
            return
        }
        let formato = {
            columna:2,
            value:[
                {
                    label: `${valor.nombres} ${valor.apellidos}`,
                    name:'titulo3',
                    nombre:'titulo3',
                    tipo:'mensaje1',
                    title: ``,
                    variant:'h6',
                    solo:true
                },
                {
                    label: `${datos.grado} ${datos.seccion}`,
                    name:'titulo2',
                    nombre:'titulo2',
                    tipo:'mensaje1',
                    title: ``,
                    variant:'h6',
                    solo:true
                },
                {
                    label: `${datos.asignatura.titulo}`,
                    name:'titulo1',
                    nombre:'titulo1',
                    tipo:'mensaje1',
                    title: ``,
                    solo:true
                }
                
            ]
        }
        let lapso = null;
        Object.keys(valor).map(val=>{
            if (['id','nombres','apellidos','cedula','periodo','grado','_id_estudiante','seccion'].indexOf(val)===-1){
                const pos = est_titulos.values.findIndex(f=> `${f.field}`===val);
                if (pos!==-1 && val.indexOf('lapso-')===-1){
                    let agregar=[]
                    if (lapso===null || lapso.value!==est_titulos.values[pos].lapso.value){
                        agregar=[
                            {
                                label: `${est_titulos.values[pos].lapso.titulo}`,
                                name:est_titulos.values[pos].lapso.value,
                                nombre:est_titulos.values[pos].lapso.value,
                                tipo:'mensaje1',
                                title: `${est_titulos.values[pos].lapso.titulo}`,
                                variant:'h6',
                                solo:true
                            }
                        ]
                        lapso=est_titulos.values[pos].lapso;
                    }

                    formato.value =[...formato.value,
                        ...agregar,
                        {
                            label: `${est_titulos.values[pos].title}`,
                            name:val,
                            nombre:val,
                            tipo:'input',
                            title: `${est_titulos.values[pos].title}`,
                            type:'number'
                        },
                        {
                            name: `filename-${val}`,
                            nombre: `filename-${val}`,
                            tipo: "Imagen",
                            label: "Imagen",
                            title: "Imagen",
                        },
                        {
                            label: `Descripción`,
                            name:`descripcion-${val}`,
                            nombre:val,
                            tipo:'input',
                            title: `Descripción`,
                            multiline: true,
                            maxRows: "2"
                        }
                    ]
                }
                
            }
            return val
        })
        let formulario = await genera_formulario({ valores:{...valor}, campos: formato });
        formulario={
            ...formulario,
            botones:[
                {
                  name:'guardar', label:'Guardar', title:'Guardar ',
                  variant:"contained", color:"success", icono:<CheckIcon/>,
                  onClick: Guardar, validar:'true', 
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Aceptar : {}},
                },
                {
                  name:'cancelar', label:'Cancelar', title:'Cancelar',
                  variant:"contained",  icono:<CancelIcon/>,
                  sx:{...Config.Estilos.Botones ? Config.Estilos.Botones.Cancelar : {}},
                  onClick: ()=>setDialogo({...dialogo,open:false})
                },
            ]
        }
        setDialogo({
            ...dialogo, 
            open: !dialogo.open,
            tam:'xs',
            Titulo:`NOTAS `,
            Cuerpo: <Formulario {...formulario}/>,
            Cerrar: ()=>setDialogo({...dialogo,open:false}),
        })
    }

    React.useEffect(()=>{
        const {User} = Ver_Valores();
        let active = true;

        if (!cargando) {
        return undefined;
        }

        if (cargando && state.formulario===undefined){
            //Inicio
            (async () => {
                let formulario = await genera_formulario({ valores:{}, campos: Form_todos('Form_Notas') });
                formulario.titulos[0].value.grado.onChange = Seleccion;
                formulario.titulos[0].value.periodo.onChange = Seleccion;
                formulario.titulos[0].value.tipo.onChange = Seleccion;
                formulario.titulos[0].value.lista.onChange = Seleccion;
                formulario.titulos[0].value.periodo.value= formulario.titulos[0].value.periodo.lista.length!==0 ? formulario.titulos[0].value.periodo.lista[0]:null;
                formulario.titulos[0].value.grado.value= formulario.titulos[0].value.grado.lista.length!==0 ? formulario.titulos[0].value.grado.lista[0]:null;
                formulario.titulos[0].value.tipo.value= formulario.titulos[0].value.tipo.lista.length!==0 
                            ? Number(User.categoria)===3 
                                ? formulario.titulos[0].value.tipo.lista[1]
                                : formulario.titulos[0].value.tipo.lista[0]
                            : null;
                formulario.titulos[0].value.lista.value= formulario.titulos[0].value.lista.lista.length!==0 ? formulario.titulos[0].value.lista.lista[0]:null;
                // formulario.titulos[0].value.lista.placeholder='Sección';
                
                
                if (active) {
                    cambio({formulario, datos:[]});
                    setCargando(false)
                    setTimeout(() => {
                        Seleccion({resultados:{
                            periodo:formulario.titulos[0].value.periodo.value,
                            grado:formulario.titulos[0].value.grado.value,
                            tipo:formulario.titulos[0].value.tipo.value,
                            lista:formulario.titulos[0].value.lista.value
                        }})
                    }, 700);
                }
            })();
            return
        }
        (async () => {
            //Buscar los datos
            const {resultados}= cargando;
            
            // let datos= await conexiones.Leer_C([`uecla_horario`, ...resultados.tipo.value==='seccion' ? ['uecla_asignatura','uecla_docente'] :[]], 
            //     {
            //         [`uecla_horario`]:{$and:[{'valores.periodo':resultados.periodo.periodo},{'valores.tipo':resultados.tipo.value}]},//{$text: {$search: resultados.carrera.nombres, $caseSensitive: false}},
            //         ...resultados.tipo.value==='seccion' ? {uecla_asignatura:{}, uecla_docente:{}} :{}
            //     }
            // );
            let est_datos=[];
            let est_cantidad=0;
            //Lo de la primera fila
            let est_titulos = await Titulos_todos(`Titulos_Notas`, Config);
            est_titulos = est_titulos.map(val=>{
                return {...val, rowspan:2}
            })
            
            let formulario_docente
            let datos= await conexiones.Leer_C(['uecla_docente'], 
                {
                    uecla_docente:{},
                }
            );
            if (datos.Respuesta==='Ok'){
                // let asignaturas = datos.datos[`uecla_asignatura`] ? datos.datos[`uecla_asignatura`].map(val=>{return {_id:val._id,...val.valores}}) : [];
                let docentes = datos.datos[`uecla_docente`] ? datos.datos[`uecla_docente`].map(val=>{return {_id:val._id,...val.valores}}) : [];
                
                // datos= datos.datos[`uecla_horario`]
                datos ={
                    periodo:resultados.periodo.periodo, 
                    tipo:resultados.tipo.value,
                };
                
                if (resultados.tipo.value==='seccion'){
                    // datos = datos.filter(f=>{
                    //     return f.valores.titulo===`${resultados.grado.titulo} ${resultados.lista.titulo}`
                    // }).map(val=>{return {...val.valores, titulo:val.valores.titulo, _id:val._id}})
                    // console.log(datos)
                    docentes = docentes.filter(f=>{
                        const o = f.grados ? f.grados.filter(f1=>f1.value===resultados.grado.value):[];
                        return o.length!==0
                    }).map(val=>{return {...val, titulo:`${val.nombres} ${val.apellidos}`}});
                    
                }else if(resultados.tipo.value==='docente'){
                    docentes = docentes.map(val=>{return {...val, titulo:`${val.nombres} ${val.apellidos}`}});
                    docentes = Number(User.categoria)===3 ? docentes.filter(f=>f._id===User._id) : docentes;
                }

                const formulario1 = await genera_formulario({ valores:{...resultados}, campos: Form_todos('Form_Notas') });
                const formulario ={...formulario1};
                formulario.titulos[0].value.grado.onChange = Seleccion;
                formulario.titulos[0].value.periodo.onChange = Seleccion;
                formulario.titulos[0].value.tipo.onChange = Seleccion;
                formulario.titulos[0].value.tipo.disabled=Number(User.categoria)===3;
                formulario.titulos[0].value.lista.onChange = Seleccion;
                if(resultados.tipo.value==='seccion'){
                    delete formulario.titulos[0].value.tipo.lista[2];
                    let pos = Ver_Valores().config.Listas.lista_colegio_grado.findIndex(f=> f._id===resultados.grado._id);
                    if (pos===-1){
                        formulario.titulos[0].value.grado.value=Ver_Valores().config.Listas.lista_colegio_grado[0];
                        datos ={
                            ...datos,
                            grado:Ver_Valores().config.Listas.lista_colegio_grado[0].titulo,
                        };
                    }
                    pos = formulario.titulos[0].value.lista.lista.findIndex(f=> f._id===resultados.lista._id);
                    if (pos===-1){
                        formulario.titulos[0].value.lista.value=formulario.titulos[0].value.lista.lista[0];
                        
                    }
                    datos={
                        ...datos,
                        grado:formulario.titulos[0].value.grado.value.titulo,
                        seccion:formulario.titulos[0].value.lista.value.titulo
                    }
                    
                } else if(resultados.tipo.value==='docente'){
                    formulario.titulos[1]={
                        listo:true, multiples:true,
                        value:{
                            grado_docente:{
                                ...formulario.titulos[0].value.grado,
                                key:'grado_docente', name:'grado_docente',
                                nombre:'grado_docente',
                                value:resultados.grado_docente
                            },
                            seccion:{
                                ...formulario.titulos[0].value.lista,
                                key:'seccion', name:'seccion',
                                nombre:'seccion',
                                value:resultados.seccion
                            },
                        }
                    }

                    // cambia grado a docente
                    formulario.titulos[0].value.grado.lista=docentes;
                    formulario.titulos[0].value.grado.placeholder='Doncente';
                    formulario.titulos[0].value.grado.title='Doncente';
                    formulario.titulos[0].value.grado.label='Doncente';
                    let pos = docentes.findIndex(f=> f._id===resultados.grado._id)
                    if (pos===-1){
                        formulario.titulos[0].value.grado.value=docentes[0];
                    }
                    datos={...datos, docente:formulario.titulos[0].value.grado.value}

                    //cambia a lista a asignatura
                    const asig_docente= formulario.titulos[0].value.grado.value.asignaturas.map(asig=>{return{...asig, titulo:`${asig.grado.titulo} ${asig.asignatura}`}});
                    formulario.titulos[0].value.lista.lista= asig_docente; 
                    formulario.titulos[0].value.lista.placeholder='Asignatura';
                    formulario.titulos[0].value.lista.title='Asignatura';
                    formulario.titulos[0].value.lista.label='Asignatura';
                    pos = asig_docente.findIndex(f=> f._id===resultados.lista._id)
                    if (pos===-1){
                        formulario.titulos[0].value.lista.value=asig_docente[0];
                    }
                    datos={...datos, asignatura:formulario.titulos[0].value.lista.value}

                    // listado de grado
                    const grado_docente= formulario.titulos[0].value.grado.value.grados;
                    console.log(asig_docente)
                    formulario.titulos[1].value.grado_docente.disabled=true;
                    formulario.titulos[1].value.grado_docente.lista= grado_docente; 
                    formulario.titulos[1].value.grado_docente.value=formulario.titulos[0].value.lista.value.grado
                    // pos = grado_docente.findIndex(f=> resultados.grado_docente && f._id===resultados.grado_docente._id)
                    // if (pos===-1){
                    //     // formulario.titulos[1].value.grado_docente.value=grado_docente[0];
                    //     formulario.titulos[1].value.grado_docente.value=formulario.titulos[0].value.lista.value.grado
                    // }
                    datos={...datos, grado:formulario.titulos[1].value.grado_docente.value.titulo}

                    // listado seccion
                    const secciones = Ver_Valores().config.Listas.lista_colegio_seccion;
                    formulario.titulos[1].value.seccion.lista= secciones; 
                    pos = secciones.findIndex(f=> resultados.seccion && f._id===resultados.seccion._id)
                    if (pos===-1){
                        formulario.titulos[1].value.seccion.value=secciones[0];
                    }
                    datos={...datos, seccion:formulario.titulos[1].value.seccion.value.titulo}

                    // formulario.titulos=[...formulario.titulos]
                    // delete formulario.titulos[0].value.lista
                    
                    // formulario_docente = await genera_formulario({ valores:{}, campos: Form_todos('Form_Notas_Docente') });
                }
                const resultado = await conexiones.Notas(datos);
                est_datos=resultado.seccion;
                
                est_cantidad= est_datos.length;
                let fila1 = resultado.titulosn.datos[0].map(asig=>{
                    return {
                        default : "",
                        field : `${asig.field}`,
                        formato : (dato)=> dato[asig.field] ? Number(dato[asig.field]).toFixed(2) : '',
                        orden: false, 
                        tipo : "",
                        title : asig.titulo,
                        type : "number",
                        lapso:asig.lapso,
                        vertical:false,
                        colspan:3,                  

                    }
                })
                const fila2 = resultado.titulosn.datos[1].map(asig=>{
                    return {
                        default : "",
                        field : `${asig.field}`,
                        formato : (dato)=> dato[asig.field] ? Number(dato[asig.field]).toFixed(2) : '',
                        orden: false, 
                        tipo : "",
                        title : asig.titulo,
                        type : "number",
                        lapso:asig.lapso,
                        vertical:asig.vertical ? asig.vertical : false, 

                    }
                })

                // const nuevotitulo = resultado.titulos.map(asig=>{
                //     return {
                //         default : "",
                //         field : `${asig.field}`,
                //         formato : (dato)=> dato[asig.field] ? Number(dato[asig.field]).toFixed(2) : '',
                //         orden: false, 
                //         tipo : "",
                //         title : asig.titulo,
                //         type : "number",
                //         lapso:asig.lapso,
                //         vertical:true,
                        

                //     }
                // })
                // est_titulos=[...est_titulos, ...nuevotitulo];
                fila1 =[...est_titulos, ...fila1];
                est_titulos={filas:2 ,datos:[fila1,fila2], values:[...est_titulos,...fila2]};
                if (active) {
                    cambio({datos, formulario, est_cantidad, est_datos, est_titulos, formulario_docente})   
                }
                
            }

            setCargando(false)
        })();

        return () => {
        active = false;
        };

    },[cargando])




    // if (state.formulario===undefined){
    //     Inicio()
        
    // }
    const alto = Ver_Valores().tipo==='Web' 
        ?   window.innerHeight * 0.65
        :   window.innerHeight * 0.68
        const color =  Ver_Valores().config.Estilos.Input_icono_t ? Ver_Valores().config.Estilos.Input_icono_t : {};
    // console.log(state.formulario ? state.formulario.titulos[0].value.tipo.value : '')
    return state.formulario ? (
        <Box sx={{ flexGrow: 1, position: "relative"}}>
            <Grid container spacing={0.5}>
                <Grid item xs={12}>
                    <Item style={{height: 'auto', color:'#fff'}}>
                        <Formulario {...state.formulario}
                            config={{
                                Estilos:{
                                    Input_label:{color:'#fff'},
                                    Input_fondo:'#fff',
                                    Input_input:{color:'#fff'}
                                }
                            }}
                        />
                    </Item>
                </Grid>
                <Grid item xs={12} >
                    {state.est_datos 
                        ?   <Item style={{}}>
                                <Tabla  Titulo={`Notas de ${state.datos ? state.datos.grado +' '+ state.datos.seccion : ''}`}
                                    alto={alto}
                                    Config={Config ? Config : Ver_Valores().config}
                                    titulos={state.est_titulos}
                                    table={'uecla_estudiante'}
                                    cantidad={state.est_cantidad ? state.est_cantidad : null}
                                    // cargacompleta={Actualizar_data}
                                    datos={state.est_datos}
                                    Accion={Abrir}
                                    Noactualizar
                                    cargaporparte={true}
                                    sinpaginacion={true}  
                                    acciones={
                                        <Stack direction="row" spacing={0.5}>
                                            <Typography >{`${state.datos && state.datos.tipo==='seccion' ? 'GENERAL' : state.datos.asignatura.titulo}`}</Typography>
                                            {
                                                state.datos && state.datos.tipo!=='seccion'
                                                    ?   <IconButton color={'primary'} title={'Evaluaciones de la asignatura'} onClick={Agregar}>
                                                            <Icon style={color}>view_list</Icon>
                                                        </IconButton>
                                                    :   null
                                            }
                                        </Stack>
                                    } 
                                />
                            </Item>
                        : null
                    }
                </Grid>
            </Grid>
            <Cargando open={cargando} Logo={Logo} Fondo={'#ffffff'}/>
            <Dialogo  {...dialogo} config={Ver_Valores().config}/>
        </Box>
    ): null;
}
