import React from 'react';
import Typography from '@mui/material/Typography';

import { CabezeraHorario } from '../reporte/cabezera';

const paginaancho=612;
const tamletra=8;

export default function ImprimirHorario(props) {
    const {Data, Table}= props;
    const MostrarI=(valores)=>{
        return(
            <div>
                <Typography fontSize={tamletra} textAlign={'center'} style={{color:'#000'}} >
                    {valores.asignatura.titulo}
                </Typography> 
                <Typography fontSize={tamletra} textAlign={'center'} style={{color:'#000'}}>
                    {valores.tipo !== 'docente' && valores.docente ? valores.docente.titulo :''}
                </Typography>
                <Typography fontSize={tamletra} textAlign={'center'} style={{color:'#000'}}>
                    {(valores.tipo === 'aula' || valores.tipo === 'docente') && valores.seccion ? valores.seccion.titulo :''}
                </Typography>
                <Typography fontSize={tamletra} textAlign={'center'} style={{color:'#000'}}>
                    {valores.aula ? valores.aula.titulo :''}
                </Typography> 
            </div>
        )
    }
    
    return (
        <div style={{height:'100%'}}>
            <CabezeraHorario Titulo={props.Datos && props.Datos.titulo ? `Horario de ${props.Datos.titulo} Periodo:${props.Datos.periodo}  ` : ''}/>
            <div style={{padding:10}}>
                <Table   >
                    <thead>
                        <tr>
                            {Data.titulo.map((valor,index)=>(
                                <th key={'th'+index} 
                                    width={index===0 ? (paginaancho/7)-25:paginaancho/7}
                                    style={{backgroundColor:'#9C9C90', color:'#fff',
                                        borderColor:'#fff',
                                        borderWidth:1,
                                        fontSize:9
                                    }}
                                >
                                    {valor}
                                </th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                        {Data.datos ? Data.datos.map((valores, index) => (
                            <tr key={'tr'+index} >
                                {valores.map((val,col)=>(
                                    val.espacio!==0
                                    ?   <td key={'td'+col} bgcolor={val['valor']==='' || col===0 ? 'black' : val.mensaje==='' ?'blue':'red' }
                                            rowSpan={val.espacio } text-align= 'center'
                                            
                                            title={val.mensaje==='' ? null : val.mensaje}
                                            className="align-middle"
                                            style={{
                                                borderColor:'#9C9C90',
                                                borderWidth:1,
                                                fontSize:9
                                            }}
                                        >
                                            
                                            {Data && Data.Hora.mensajereceso===val.mensaje && col!==0
                                                            ?   <Typography fontSize={8} textAlign={'center'} style={{color:'#000'}}>
                                                                    {Data.Hora.mensajereceso}
                                                                </Typography>
                                                            :
                                            !val.asignatura 
                                                ?   <Typography fontSize={8} textAlign={'center'} style={{color:'#000'}}>
                                                        {col!==0 ? val.valor : val.valor.split('-')[0]}
                                                        {col===0 ? <br/> : null}
                                                        {col===0 ? '-' : null}
                                                        {col===0 ? <br/> : null}
                                                        {col===0 ?  val.valor.split('-')[1]: null}
                                                    </Typography>  
                                                : <MostrarI {...val}/>
                                            }
                                        
                                        </td>
                                    :   null
                                ))}

                            </tr>
                        )):null}

                    </tbody>
                </Table>
            </div>
        </div>
    )
}