import Respaldo from "./respaldo"
import Sincronizar from "./sincronizar";
import Direcciones from './direccionestudiante';
import Conectados from "./conectados";
import WhatsApp from "./whatsapp";
export const colegio_configuracion_pantallas={
    Respaldo,
    Sincronizar,
    Direcciones,
    Conectados,
    WhatsApp
}